// @font-face {
//     font-family: 'av';
//     src: url('../public/assets/aeonik/AeonikProTRIAL-Light.otf');
//     font-weight: 300;
// }

// @font-face {
//     font-family: 'aeonik pro';
//     src: url('../public/assets/aeonik/AeonikProTRIAL-Regular.otf');
//     font-weight: 400;
// }

// @font-face {
//     font-family: 'aeonik pro';
//     src: url('../public/assets/aeonik/AeonikProTRIAL-Bold.otf');
//     font-weight: 700;
// }
@import "../public/styles/bootstrap";

@font-face {
    font-family: "avenir";
    font-weight: 300;
    src: local("avenir"),
        url("../public/fonts/Avenir/AvenirLTStd-Light.woff") format("woff");
}

@font-face {
    font-family: "avenir";
    font-weight: 400;
    src: local("avenir"),
        url("../public/fonts/Avenir/AvenirLTStd-Book.woff") format("woff");
}

@font-face {
    font-family: "avenir";
    font-weight: 500;
    src: local("avenir"),
        url("../public/fonts/Avenir/AvenirLTStd-Medium.woff") format("woff");
}

@font-face {
    font-family: "avenir";
    font-weight: 600;
    src: local("avenir"),
        url("../public/fonts/Avenir/AvenirLTStd-Heavy.woff") format("woff");
}

@font-face {
    font-family: "avenir";
    font-weight: 700;
    src: local("avenir"),
        url("../public/fonts/Avenir/AvenirLTStd-Black.woff") format("woff");
}

// variables
$primary: #0c4da2;

html {
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    // font-size: 10px;
    scroll-behavior: smooth;

    &:has(.mobileNav-open) {
        overflow: hidden;
    }
}

body {
    color: #212121;

    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "avenir", sans-serif;
    font-weight: 500;
    /* font-size: 62.5%; */
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

// custom styling
.text-primary {
    color: $primary !important;
}

.ant-form-item-label {
    >label {
        display: inline-flex;
        flex-direction: row-reverse;

        &:after {
            display: none;
        }

        &.ant-form-item-required {
            display: inline-flex;
            flex-direction: row-reverse;
            gap: 3px;
        }
    }
}

@media (min-width: 1024px) {
    .top-left-wrapper {
        width: calc(100% - 200px);
        padding: 3px 0;
    }

    .top-right-wrapper {
        right: 0;
        position: absolute;
        top: 3px;
    }

    .button-wrap a,
    .button-wrap .dropdown button {
        font-size: 15px;
    }

    span.menu-img {
        padding: 5px 10px;
    }

    ul.single-menu-item.wsmenu-submenu {
        margin-right: 100%;
        top: 0;
        right: 0;
    }

    ul.top-nav-left li:after {
        content: "";
        width: 1px;
        height: 16px;
        position: absolute;
        background-color: #0c4da2;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    .first-level>li>span {
        display: none;
    }

    .dropdown-menu.show {
        top: 20px !important;
    }
}

ul.top-nav-left.top-nav-left.d-flex li {
    list-style: none;
    padding: 3px 5px;
    position: relative;
}

ul.top-nav-left a {
    color: #0c4da2;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
}

ul.top-nav-left a:hover {
    color: #ed1c24;
}

ul.top-nav-left li.new {
    position: relative;
    padding-right: 38px !important;
}

ul.top-nav-left li.new span {
    font-size: 10px;
    animation-name: bgcolor;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    padding: 0px 5px;
    border-radius: 8px;
    color: #fff;
    border: none !important;
    position: absolute;
    top: 6px;
    right: 5px;
}

@keyframes bgcolor {
    0% {
        background-color: #0c4da2;
    }

    50% {
        background-color: #ed1c24;
    }

    100% {
        background-color: #0c4da2;
    }
}

span.language-toggle {
    margin: 0 15px;
    border-radius: 2px;
    background-color: #2b75cc;
    padding: 3px;
    font-size: 15px;
}

span.language-toggle a {
    color: #f6faff;
    padding: 2px;
    text-decoration: none;
}

.language-toggle .active {
    border-radius: 2px;
    color: #0a50a3;
    background-color: #fff;
}

.top-right-wrapper {
    position: relative;
}

.top-right-wrapper button {
    position: absolute;
    left: 0;
    background: transparent;
    border: none;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
}

.top-right-wrapper input {
    padding-left: 30px;
    background-color: #2b75cc;
    border: none;
    height: 32px;
    border-radius: 4px;
}

.top-right-wrapper input::placeholder {
    color: #fff;
}

.home-img {
    width: 450px;
}

li.first-main-nav>a {
    font-size: 16px;
    color: #fff;
    border-right: 1px solid #f2f2f2;
}

li.first-main-nav:last-child>a {
    border-right: none;
}

li.first-main-nav>a span {
    color: #f2f2f2 !important;
    font-size: 12px !important;
}

.headerfull {
    background: #ed1c24;

    .navtext {
        text-decoration: none;
        display: flex;
        flex-direction: column;
    }
}

.overlay.bag-img {
    padding: 1.5rem 0 !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin-top: 1.5rem;
}

.steps {
    padding: 45px 0 35px 0;
}

main {}

// <style> {
//     ` .ant-steps {
//         width: 100%;
//         /* Full width */
//     }

//     .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item::after {
//         height: 12px;
//         /* Thickness of the progress line */
//         background-color: #ccc;
//         /* Default color for progress line */
//     }

//     .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
//         background-color: #52c41a;
//         /* Finished step color */
//     }

//     .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
//         background-color: #1890ff;
//         /* Current step color */
//     }

//     `
// }

// </style>

// .ant-steps .ant-steps-item-container{
//     text-align: center;
// }

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background-color: #eaf0f4;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #eaf0f4;
    z-index: -1;
    position: relative;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #ed1c24;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    width: 60px;
    height: 60px;
    color: #2e328a;
    background: #eaf0f4;
}

.ant-steps .ant-steps-item-process.ant-steps-item-custom {
    &.ant-steps-item-active {
        .ant-steps-item-icon {

            background-color: #ed1c24;

            >.ant-steps-icon {
                color: white;
            }
        }
    }

    .ant-steps-item-icon>.ant-steps-icon {}
}

.ant-steps .ant-steps-item-title {
    line-height: 1.4;
}

.ant-upload-list.ant-upload-list-text {
    display: none;
}