/* *{
  margin: 0;
  padding: 0;
} */
.demo-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px;
  /* background-color: #f5f5f5; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}



.form-groupp {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 20px;
}

.home-buttons{
display: block;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 20px;

}
/* label {
  color: #2e328a !important;
  font-weight: 600;
  font-size: 14px;
  font-family: "Avenir LT Std 65 Medium";
} */

.google-map-container {
  width: 100%;
  height: 400px;
  margin-bottom: 1rem;
}


/* Remove borders from form elements */
 .form-group,
 
 fieldset {
  border: none !important;
}

/* Remove box-shadow that might be giving a border-like appearance */
.form-group,

 fieldset {
  box-shadow: none !important;
}

/* If you want to add your own subtle border, you can do something like this */
/* .form-group {
 background-color:white !important;
  padding-bottom: 15px;
  margin-bottom: 15px;
} */
